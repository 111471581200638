// src/components/Header.js
import React from 'react';

const Header = ({ headline }) => {
    return (
        <header className="mb-4 pt-5">
            <h1 className="text-center">{headline}</h1>
        </header>
    );
}

export default Header;
