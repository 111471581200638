// src/App.js
import React from 'react';
import Header from './components/Header';
import Logo from './components/Logo';
import './App.css';
import hLogo from './assets/h_logo.png'
import jLogo from './assets/j_logo.png'

function App() {
  return (
    <div className="d-flex flex-column vh-100 align-items-center bg">
      <div className="header">
        <Header headline="Welcome to our world!" className="display-4 text-white mb-5"/>
      </div>
      <div className="overlay flex-grow-1 d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-around align-items-center">
            <div className="col-12 col-md-6 mb-4 text-center">
              <a href="https://highpmangroup.com">
                <Logo className="small-logo img-fluid shadow" src={hLogo} alt="HighPMan Group" />
              </a>
              <p className="mt-2">HighPMan Group</p>
            </div>
            <div className="col-12 col-md-6 mb-4 text-center">
              <a href="https://jfalliancegroup.com"> 
                <Logo className="small-logo img-fluid shadow" src={jLogo} alt="J&F Alliance Group" />
              </a>
              <p className="mt-2">J&F Alliance Group</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
