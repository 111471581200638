// src/components/Logo.js
import React from 'react';

const Logo = ({ src, alt }) => {
    return (
        <div className="container">
            <div className="container text-center">
                <img src={src} alt={alt} className="small-logo img-fluid" />
            </div>
        </div>

    );
}

export default Logo;
